import * as React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./providers/data-provider";
import {
  EventList,
  EventCreate,
  EventEdit,
  EventShow,
} from "./Components/Event/Event";

import {
  ImageList,
  ImageCreate,
  ImageEdit,
  ImageShow,
} from "./Components/Gallery/Gallery";
import {
  UsersList,
  UserCreate,
  UserEdit,
  UserShow,
} from "./Components/Users/User";
import {
  FoodtruckList,
  FoodtruckCreate,
  FoodtruckEdit,
  FoodtruckShow,
} from "./Components/Foodtruck/Foodtruck";
import {
  TicketsList,
  TicketCreate,
  TicketEdit,
  TicketShow,
} from "./Components/Tickets/Tickets";

import {
  PartnerCreate,
  PartnerEdit,
  PartnerList,
  PartnerShow,
} from "./Components/Partner/Partner";

import { FaqCreate, FaqEdit, FaqsList, FaqShow } from "./Components/Faq/Faq";

import {
  LocationCreate,
  LocationEdit,
  LocationsList,
  LocationShow,
} from "./Components/Location/Location";

import Dashboard from "./Components/Dashboard/Dashboard";
import authProvider from "./providers/auth-provider";
import FeedIcon from "@material-ui/icons/ViewHeadline";
import UserIcon from "@material-ui/icons/Person";
import PhotoIcon from "@material-ui/icons/Photo";
import FoodtruckIcon from "@material-ui/icons/DirectionsBus";
import TicketIcon from "@material-ui/icons/LocalActivity";
import PartnerIcon from "@material-ui/icons/MonetizationOn";
import FaqIcon from "@material-ui/icons/QuestionAnswer";
import LocationIcon from "@material-ui/icons/PersonPinCircle";

const App = () => {
  return (
    <Admin
      title="Malaga Forum Admin"
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
    >
      {(permissions) => [
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="events"
            show={EventShow}
            list={EventList}
            create={EventCreate}
            edit={EventEdit}
            icon={FeedIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="tickets"
            show={TicketShow}
            list={TicketsList}
            create={TicketCreate}
            edit={TicketEdit}
            icon={TicketIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="gallery"
            show={ImageShow}
            list={ImageList}
            create={ImageCreate}
            edit={ImageEdit}
            icon={PhotoIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="faqs"
            show={FaqShow}
            list={FaqsList}
            create={FaqCreate}
            edit={FaqEdit}
            icon={FaqIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="foodtrucks"
            show={FoodtruckShow}
            list={FoodtruckList}
            create={FoodtruckCreate}
            edit={FoodtruckEdit}
            icon={FoodtruckIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="partners"
            show={PartnerShow}
            list={PartnerList}
            create={PartnerCreate}
            edit={PartnerEdit}
            icon={PartnerIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="locations"
            show={LocationShow}
            list={LocationsList}
            create={LocationCreate}
            edit={LocationEdit}
            icon={LocationIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "superAdmin" ? (
          <Resource
            name="users"
            list={UsersList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
            icon={UserIcon}
          />
        ) : null,
      ]}
    </Admin>
  );
};

export default App;
