import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";


export default () => (
  <>
    <Card>
      <CardHeader title="Dashboard" />
      <CardContent>
        Herramienta de gestión de Málaga Forum
      </CardContent>
    </Card>
  </>
);
